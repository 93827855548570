import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import Axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader"
import Usuario from './usuario'
import UsuarioPerfil from './usuarioPerfil'
import Config from '../../../../config'

export default class lstUsuario extends Component {

  state = {
        result: [],
        id: null,
        idCliente: '',
        lstClientes: []
      }

  relatorio() {
    if (this.state.result){
      let _result = this.state.result.filter(i => i.idCliente==this.state.idCliente || this.state.idCliente=='')
      if (_result.length == 0 )
        return (
          <center><h2>Não há usuários cadastrados.</h2></center>
        )
        
      return _result && (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{whiteSpace: "nowrap"}}>Usuário</th>
              <th style={{width:"175px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {_result.map((item, index) =>
              <tr key={index}>
                <td style={{whiteSpace: "nowrap"}}>{`${item.nome}`}</td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<Usuario id={`${item.id}`} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-info" alt="teste" onClick={()=>ReactDOM.render(<UsuarioPerfil id={`${item.id}`} action={`edit`} />, document.getElementById('Content'))}><i className="fa fa-check"></i></button>
                    <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<Usuario id={`${item.id}`} action={`delete`} />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
          <tr>
            <th style={{whiteSpace: "nowrap"}}>Usuário</th>
            <th style={{width:"85px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
          </tr>
        </tfoot>
      </table>
      )
    }
  }

  async listarClientes() {
    await Axios.post(`${Config.dominio}/php/sistema/cliente_list.php`,
                     JSON.stringify({
                     })
    )
    .then( async (response) => {
        this.setState({lstClientes: response.data.sort((a,b) => a.nome > b.nome? 1:-1)})
    }) 
    .catch(erro => console.log(erro))
  }

  async componentWillMount(){
    await Axios.post(`${Config.dominio}/php/sistema/usuario_list.php`, 
        JSON.stringify({
          idCliente: this.state.idCliente,
          local: sessionStorage.getItem('local')
        })
    )
    .then( async (response) => this.setState({result: response.data})) 
    .catch(erro => console.log(erro))
  }

  componentDidMount() {
    this.listarClientes()
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Usuários
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={()=>ReactDOM.render(<Usuario action={`new`} cliente={this.state.idCliente} id={0} />, document.getElementById('Content'))}> Novo Usuário</button>
        </ol>
        </section>
        <section className="content"> 
        <div className="box">
            <div className="box-header">
              <div className="form-group">
                <div className="row">
                  <div className="col-xs-8">
                    <div className="input-group">
                      <select
                        className="form-control select2"
                        value={this.state.idCliente}
                        onChange={e => this.setState({idCliente: e.target.value})}
                      >
                        <option key={-1} value=''>Escolha um Cliente</option>
                        {this.state.lstClientes &&
                          this.state.lstClientes.map((item, index) => <option key={index} value={item.id}>{item.nome}</option>)
                        }
                      </select>
                    </div>
                  </div>  
                </div> 
              </div>
            </div>
            <div className="box-body">
            <ClipLoader
                  css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
                  color={"#123abc"}
                  loading={!this.state.result}
              />
              {this.state.result && this.relatorio()}
            </div>
          </div>
        </section>
      </>
    )
  }
}