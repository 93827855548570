import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import { AxiosPost } from '../../../../lib/utils3'
import DetalheOS from '../acompanhamento/detalheOS'
import Select from 'react-select'
import { format } from 'date-fns'
import makeAnimated from 'react-select/animated'
import Config from '../../../../config'

const animatedComponents = makeAnimated()

export default class callcenterConsulta extends Component { 

  state = { result: null, 
            lstStatusPesquisa: null,
            lstEquipe:[],
            tecnicos: [],
            lstBairros: [],
            bairro:[],
            contribuinte: [],
            lstDistritos:[],
            distrito:[],
            lstStatus: [],
            showFiltro: true,
          }
  
  async listaStatus() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`,
                    JSON.stringify({tabela: 5})
    )
    .then(async response => {
      let lista = []
      response.data.map(item => lista.push({value:item.valor , label:item.descricao }))
      this.setState({lstStatus: lista})
    })
    .catch(erro => console.log(erro))
  }

  async listaTecnico() {
    await AxiosPost(`${Config.dominio}/php/equipe/tecnico_list.php`, JSON.stringify({ a:1}))
    .then(async response => {
      let lista = []
      response.data.map(item => lista.push({value: item.id, label: item.nome}))
      this.setState({lstEquipe: lista})
    })
    .catch(erro => console.log(erro))
  }

  relatorio() {
    if (this.state.result && this.state.result.length == 0) {
      return <center><h2>Não há Ordens de Serviço.</h2></center>
    } else {
     return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Emissão</th>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>OS</th>
              <th style={{width: "1px", whiteSpace: "nowrap"}}>equipe</th>
              <th style={{width: "30px", whiteSpace: "nowrap"}}>Contribuinte</th>
              <th style={{whiteSpace: "nowrap"}}>descrição do problema</th>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Situação</th>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Baixada em</th>
              <th style={{width:"175px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </thead> 
          <tbody>
            {this.state.result.map((item, index) =>
              <tr key={index}>
                <td style={{textAlign: "center", whiteSpace: "nowrap"}}>{format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")}</td>
                <td style={{textAlign: "center", whiteSpace: "nowrap"}}>{item.protocolo}</td>
                <td style={{whiteSpace: "nowrap"}}>{item.nome_equipe}</td>
                <td style={{whiteSpace: "nowrap"}}>{item.nome_contribuinte}</td>
                <td style={{whiteSpace: "nowrap"}}>{item.desc_problema}</td>
                <td style={{whiteSpace: "nowrap"}}>{this.state.lstStatus.length>0 && this.state.lstStatus.find(st => st.value==item.id_status_atual).label}</td>
                <td style={{whiteSpace: "nowrap"}}>{[4,6,7].find(i => i==item.id_status_atual)?format(new Date(item.datahora_atual), "dd/MM/yyyy HH:mm"): ''}</td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" alt="teste" onClick={()=>ReactDOM.render(<DetalheOS protocolo={`${item.protocolo}`} edit={0} />, document.getElementById('Content'))}><i className="fa fa-sitemap"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
          <tr>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Emissão</th>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>OS</th>
              <th style={{width: "1px", whiteSpace: "nowrap"}}>equipe</th>
              <th style={{whiteSpace: "nowrap"}}>descrição do problema</th>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Situação</th>
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>Baixada em</th>
              <th style={{width:"175px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </tfoot>
        </table>
     )
    }
  }

  async pesquisar() {
    let filtroValor = this.state.filtroValor
    let filtroPlaceholder = this.state.filtroPlaceholder
    let _lstBairros = []
    this.state.bairro.length>0 && this.state.bairro.map(item => _lstBairros.push(item.value))
    let _lstDistritos =[]
    this.state.distrito.length>0 && this.state.distrito.map(item => _lstDistritos.push(item.value))
    await AxiosPost(`${Config.dominio}/php/os/ordemServico_list.php`, 
                      JSON.stringify({
                                      filtro_barramento: filtroPlaceholder=='Barramento'? filtroValor: '',
                                      filtro_distrito: filtroPlaceholder=='Distrito'? _lstDistritos.toString(): '',
                                      filtro_bairro: filtroPlaceholder=='Bairro'? _lstBairros.toString(): '',
                                      filtro_contribuinte: filtroPlaceholder=='Contribuinte'? filtroValor: '',
                                      filtro_rua: filtroPlaceholder=='Logradouro'? filtroValor: '',
                                      filtro_os: filtroPlaceholder=='OS'? filtroValor: '',
                                      filtro_dataAbertura: filtroPlaceholder=='Data de Abertura'? this.state.dataAbertura: ''
                                    })
    )
    .then (async function (response) {
      let bairros = []
      let _bairros = []
      let distritos=[]
      let _distritos=[]

      response.data.map(item => bairros.push(item.bairro))
      bairros = [...new Set(bairros)]
      bairros.map(item => item!="null" &&  _bairros.push({value: item, label: item}))
      
      response.data.map(item => distritos.push(item.distrito+'|'+item.id_distrito))
      distritos = [...new Set(distritos)]
      distritos.map(item => item!="null|null" && _distritos.push({value: item.split("|")[1], label: item.split("|")[0]}))
      
      this.setState({
                      result: response.data,
                      totalRegistros: response.data.length,
                      lstBairros: _bairros, 
                      lstDistritos: _distritos
                   })
    }.bind(this))
    .catch(erro => console.log(erro))
  }

  async componentDidMount() {
    this.listaStatus()
    this.listaTecnico()
//    this.pesquisar()
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Consultar protocolo de atendimento
          </h1>
          <ol className="breadcrumb">
          </ol>
        </section>
        <section className="content">
          <div className="box">
            <p />
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => this.setState({filtroPlaceholder:'Barramento'})}>Barramento</a></li>
                    <li><a href="#" onClick={() => this.setState({filtroPlaceholder:'Contribuinte'})}>Contribuinte</a></li>
                    <li><a href="#" onClick={() => this.setState({filtroPlaceholder:'Distrito'})}>Distrito</a></li>
                    <li><a href="#" onClick={() => this.setState({filtroPlaceholder:'Bairro'})}>Bairro</a></li>
                    <li><a href="#" onClick={() => this.setState({filtroPlaceholder:'Logradouro'})}>Logradouro</a></li>
                    <li><a href="#" onClick={() => this.setState({filtroPlaceholder:'OS'})}>OS</a></li>
                    <li><a href="#" onClick={() => this.setState({filtroPlaceholder:'Data de Abertura'})}>Data</a></li>
                  </ul>
                </div>
                {this.state.filtroPlaceholder!='Distrito' && 
                  this.state.filtroPlaceholder!='Bairro' &&
                  this.state.filtroPlaceholder!='Data de Abertura' &&
                  <input type="text" onChange={e => this.setState({filtroValor:e.target.value})}  className="form-control" placeholder={this.state.filtroPlaceholder} />
                }
                {this.state.filtroPlaceholder=='Distrito' &&
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={this.state.lstDistritos}
                    style={{width: '100%'}}
                    value={this.state.distrito}
                    onChange={selectedOption => this.setState({distrito: selectedOption})}
                  />
                }
                {this.state.filtroPlaceholder=='Bairro' &&
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={this.state.lstBairros}
                    style={{width: '100%'}}
                    value={this.state.bairro}
                    onChange={selectedOption => this.setState({bairro: selectedOption})}
                  />
                }
                {this.state.filtroPlaceholder=='Data de Abertura' &&
                  <input
                    type="date"
                    className="form-control"
                    value={this.state.dataAbertura}
                    onChange={event => this.setState({dataAbertura: event.target.value})}
                  />
                }
                <span className="input-group-btn">
                  <button type="button" className="btn btn-info btn-flat" onClick={() => this.pesquisar() }>Pesquisar</button>
                </span>
              </div>
            </div>
          </div>
          <p />
          <div className="box-body">
            <p />
            <p />

            {this.state.result && this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}