import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import Axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Config from '../../../../config'
import LstUsuario from './lstUsuario'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()

export default class usuarioPerfil extends Component {

  state = {
            id: 0,
            nome: '',
            usuario: '',
            idCliente: 0,
            menuInicial: 0,
            lstClientes: [],
            lstPermissoes: [],
            lstFuncionalidades: [],
            lstPermissoesFuncionalidade: [],
            lstUsuarioFuncionalidadePermisao: [],
            aba: "dashboard"
          }
  salvar = this.salvar.bind(this)
  
  async salvar() {

    let listaPermissoes = []
      this.state.lstUsuarioFuncionalidadePermisao.map(item => listaPermissoes.push(item.idPermissao))
    let listaFuncionalidades = []
      this.state.lstUsuarioFuncionalidadePermisao.map(item => listaFuncionalidades.push(item.idFuncionalidade))
    await Axios.post(`${Config.dominio}/php/sistema/usuarioFuncionalidadePermissao_update.php`,
                     JSON.stringify({
                      idUsuario: this.state.id,
                      menuInicial: this.state.menuInicial,
                      lstPermissao: listaPermissoes.toString(),
                      lstFuncionalidade: listaFuncionalidades.toString()
                     })
    )
    .then(async function(response) {
      toast.success('Perfil de acessos do usuário registrado!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: ReactDOM.render(<LstUsuario />, document.getElementById('Content'))
      })
    })
    .catch(erro => console.log(erro))
  }

  async permissoesFuncionalidade() {
    await Axios.post(`${Config.dominio}/php/sistema/permissaoFuncionalidade_list.php`) 
    .then(async  (response) => {
      this.setState({lstPermissoesFuncionalidade: response.data})
    })
    .catch(erro => console.log(erro))
    
  }

  async funcionalidades() {
    await Axios.post(`${Config.dominio}/php/sistema/funcionalidades_list.php`) 
    .then(async  (response) => {
      this.setState({lstFuncionalidades: response.data})
    })
    .catch(erro => console.log(erro))
    
  }

  async usuarioFuncionalidadePermisao() {
    await Axios.post(`${Config.dominio}/php/sistema/usuarioFuncionalidadePermissao_list.php`,
                    JSON.stringify({
                      idUsuario: this.props.id 
                    })
    )
    .then(async (response) => this.setState({lstUsuarioFuncionalidadePermisao: response.data}))
    .catch(erro => console.log(erro))
  }

  atualizaPermissoes(idFuncionalidade, selectedOption) {
    // limpar os elementos de lstUsuarioFuncionalidadePermisao para esta funcionalidade
    let _lista = []
    this.state.lstUsuarioFuncionalidadePermisao.forEach(item => {
      if (!(item.idUsuario == this.state.id && item.idFuncionalidade == idFuncionalidade)) _lista.push(item)
    })
    selectedOption.forEach(item => _lista.push({idUsuario: this.state.id, idFuncionalidade: idFuncionalidade, idPermissao: item.value}))
    this.setState({lstUsuarioFuncionalidadePermisao: _lista})
  }

  async clientes(){
    await Axios.post(`${Config.dominio}/php/sistema/cliente_list.php`, JSON.stringify({}))
    .then( async (response) => this.setState({lstClientes: response.data.sort((a,b) => a.nome > b.nome? 1:-1)})) 
    .catch(erro => console.log(erro))
  }

  async permissoes() {
    await Axios.post (`${Config.dominio}/php/sistema/generica_list.php`,
                      JSON.stringify({
                        tabela: 1
                      })
    )
    .then( async (response) => {
      let _lstPermissoes=[]
      response.data.map(item => _lstPermissoes.push({ value: item.valor, label: item.descricao}))
      this.setState({ lstPermissoes: _lstPermissoes })
    })
    .catch(erro => console.log(erro))
  }

  menu(_id) {
    return  this.state.lstFuncionalidades.filter(item=> item.childID==_id).map((_item, _index) => 
              <div key={_index} className="form-group">
                  <input type="radio" defaultChecked={this.state.menuInicial==_item.childID?"checked":""}  name="menuInicial" onClick={e => this.setState({menuInicial: _item.childID})} /> <label>Menu {_item.name}</label>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={this.state.lstPermissoes.filter(item => this.state.lstPermissoesFuncionalidade.filter(item => item.idFuncionalidade==_id).map(i => i.idPermissoes).includes(item.value))}
                  style={{width: '100%'}}
                  value={this.state.lstPermissoes.filter(opcoes => this.state.lstUsuarioFuncionalidadePermisao.filter(i => i.idFuncionalidade==_id).map(ii => ii.idPermissao).includes(opcoes.value))}
                  onChange={selectedOption => this.atualizaPermissoes(_item.childID, selectedOption)}
                />
              </div>
            )
  }

  itensMenu(_id) {
    return  this.state.lstFuncionalidades.filter(item=> item.parentID==_id).map((_item, _index) => 
              <div key={_index} className="form-group">
                <input type="radio" defaultChecked={this.state.menuInicial==_item.childID?"checked":""} name="menuInicial" onClick={e => this.setState({menuInicial: _item.childID})} /> <label>{_item.name}</label>
                { <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={this.state.lstPermissoes.filter(i => this.state.lstPermissoesFuncionalidade.filter(ii => ii.idFuncionalidade==_item.childID).map(iii => iii.idPermissoes).includes(i.value))}
                    style={{width: '100%'}}
                    value={this.state.lstPermissoes.filter(opcoes => this.state.lstUsuarioFuncionalidadePermisao.filter(i => i.idFuncionalidade==_item.childID).map(ii => ii.idPermissao).includes(opcoes.value))}
                    onChange={selectedOption => this.atualizaPermissoes(_item.childID, selectedOption)}
                  />
                }
              </div>
            )
  }

  async componentWillMount() {

    this.permissoes()
    this.clientes()
    this.funcionalidades()
    this.permissoesFuncionalidade()
    this.usuarioFuncionalidadePermisao()
    console.log(this.state.lstUsuarioFuncionalidadePermisao)
    if (this.props.action == "edit") {
      await Axios.post(`${Config.dominio}/php/sistema/usuario_list.php`,
                        JSON.stringify({
                          id: this.props.id
                        })
      )
      .then(async (response) => this.setState({
                                                id: this.props.id,
                                                idCliente: response.data[0].idCliente,
                                                nome: response.data[0].nome,
                                                menuInicial: response.data[0].menuInicial
                                              }))
      .catch(erro => console.log(erro))

    }
  }

  render() {
    return (
    <>
      <ToastContainer />
      <section className="content-header">
        <h1>
          Usuários
          <small>Perfil de acesso ao GIP</small>
        </h1>
        <ol className="breadcrumb">
          <li>
            <button 
              className="btn btn-danger btn-flat" 
              onClick={() => ReactDOM.render(<LstUsuario />, document.getElementById('Content'))}
            >
              Voltar
            </button>&nbsp;&nbsp;
            <button className="btn btn-primary" onClick={this.salvar} >Salvar</button>
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="col-md-4">
          <div className="box">
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Descrição</h3>
                  <div className="box-body"> 
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="form-group">
                          <label htmlFor="nome">Nome</label>
                          <input 
                            type="text" 
                            value={`${this.state.nome}`} 
                            className="form-control" 
                            id="nome" 
                            placeholder="" 
                            onChange={event => this.setState({nome: event.target.value})}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="form-group">
                          <label htmlFor="cliente">Cliente</label>
                          <select
                            className="form-control select2"
                            value={this.state.idCliente}
                            disabled={this.state.idCliente!=''}
                            onChange={e => {
                                            this.setState({idCliente: e.target.value})
                                            this.listarUsuarios(e.target.value)
                                          }
                                    }
                          >
                            <option key={-1} value=''>Escolha um Cliente</option>
                            {this.state.lstClientes &&
                              this.state.lstClientes.map((item, index) => <option key={index} value={item.id}>{item.nome}</option>)
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                <div className="box-footer">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="box">
            <div className="box box-success">
              <div className="box-header with-border">
                <h3 className="box-title">Acessos</h3>
                  <div className="box-body">
                    <div className="nav-tabs-custom">
                      <ul className="nav nav-tabs">
                        <li className="active"><a href="#_dashboard" data-toggle="tab" onClick={e => this.setState({aba: "dashboard"})}>Dashboard</a></li>
                        <li><a href="#_cadastro" data-toggle="tab" onClick={e => this.setState({aba: "cadastro"})}>Cadastro</a></li>
                        <li><a href="#_controleMateriais" data-toggle="tab" onClick={e => this.setState({aba: "controleMateriais"})}>Controle de Materiais</a></li>
                        <li><a href="#_ordemServico" data-toggle="tab" onClick={e => this.setState({aba: "ordemServico"})}>Ordem de Serviço</a></li>
                        <li><a href="#_diarioObras" data-toggle="tab" onClick={e => this.setState({aba: "diarioObras"})}>Diário de Obras</a></li>
                        <li><a href="#_medições" data-toggle="tab" onClick={e => this.setState({aba: "medições"})}>Medições</a></li>
                        <li><a href="#_telegestao" data-toggle="tab" onClick={e => this.setState({aba: "telegestao"})}>Telegestão</a></li>
                        <li><a href="#_georreferenciamento" data-toggle="tab" onClick={e => this.setState({aba: "georreferenciamento"})}>Georreferenciamento</a></li>
                        <li><a href="#_sistema" data-toggle="tab" onClick={e => this.setState({aba: "sistema"})}>Sistema</a></li>
                        <li><a href="#_relatorios" data-toggle="tab" onClick={e => this.setState({aba: "relatorios"})}>Relatorios</a></li>
                      </ul>
                      <div className="tab-content">
                        <div key="00" className="active tab-pane" id="_dashboard">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade && 
                            this.state.aba == "dashboard" &&
                            <>
                              {this.menu(1)}
                              {this.itensMenu(1)}
                            </>
                          }
                        </div>
                        <div key="01" className="tab-pane" id="_cadastro">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade && 
                            this.state.aba == "cadastro" &&
                            <>
                              {this.menu(18)}
                              {this.itensMenu(18)}
                            </>
                          }
                        </div>
                        <div key="02" className="tab-pane" id="_controleMateriais">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade &&
                            this.state.aba == "controleMateriais" &&
                            <>
                              {this.menu(19)}
                              {this.itensMenu(19)}
                            </>
                          }
                        </div>
                        <div key="03" className="tab-pane" id="_ordemServico">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade &&
                            this.state.aba == "ordemServico" &&
                            <>
                              {this.menu(10)}
                              {this.itensMenu(10)}
                            </>
                          }
                        </div>
                        <div key="04" className="tab-pane" id="_diarioObras">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade &&
                            this.state.aba == "diarioObras" &&
                            <>
                              {this.menu(21)}
                              {this.itensMenu(21)}
                            </>
                          }
                        </div>
                        <div key="05" className="tab-pane" id="_medições">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade &&
                            this.state.aba == "medições" &&
                            <>
                              {this.menu(20)}
                              {this.itensMenu(20)}
                            </>
                          }
                        </div>
                        <div key="06" className="tab-pane" id="_telegestao">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade &&
                            this.state.aba == "telegestao" &&
                            <>
                              {this.menu(22)}
                              {this.itensMenu(22)}
                            </>
                          }
                        </div>
                        <div key="07" className="tab-pane" id="_georreferenciamento">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade &&
                            this.state.aba == "georreferenciamento" &&
                            <>
                              {this.menu(9)}
                              {this.itensMenu(9)}
                            </>
                          }
                        </div>
                        <div key="08" className="tab-pane" id="_sistema">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade &&
                            this.state.aba == "sistema" &&
                            <>
                              {this.menu(5)}
                              {this.itensMenu(5)}
                            </>
                          }
                        </div>
                        <div key="09" className="tab-pane" id="_relatorios">
                          { this.state.lstFuncionalidades && 
                            this.state.lstPermissoes && 
                            this.state.lstPermissoesFuncionalidade &&
                            this.state.aba == "relatorios" &&
                            <>
                              {this.menu(23)}
                              {this.itensMenu(23)}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                <div className="box-footer">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    )
  }
}