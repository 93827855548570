import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import Axios from 'axios'
import { usuario } from '../../../lib/utils2'
import Config from '../../../config'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import makeAnimated from 'react-select/animated'
import LstFuncionalidades from './lstFuncionalidades'

const animatedComponents = makeAnimated()

export default class funcionalidade extends Component {

  state = {
            result: null,
            lstPermissoes: [],
            lstPermissoesEscolhidas: [],
            childID: '',  
            parentID: '',  
            name: '',
            descricao: '', 
            childcount: '',
            level: ''
          }
  salvar = this.salvar.bind(this)

  async salvar(e) {
    e.preventDefault()
    let listaPermissoesEscolhidas = []
    this.state.lstPermissoesEscolhidas.map(item => listaPermissoesEscolhidas.push(item.value))
    await Axios.post(`${Config.dominio}/php/sistema/permissaoFuncionalidade_update.php`,
            JSON.stringify({
              lstPermissao: listaPermissoesEscolhidas.toString(),
              idFuncionalidade: this.state.id
            })
    )
    .then(async response => 
      toast.success('Permissões da Funcionalidade registradas!',
                     {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      onClose: ReactDOM.render(<LstFuncionalidades />, document.getElementById('Content'))
                    })
    )
    .catch(erro => console.log(erro))
  }
  
  async componentDidMount() {
    let _lstPermissoes=[]
    let _lstPermissoesEscolhidas = []

    await Axios.get (`${Config.dominio}/php/sistema/funcionalidades_list.php`)
    .then( async (response) => {
      const {childID, parentID, name, descricao, childcount, level} = response.data.filter(item => item.childID==this.props.id)[0]
      this.setState({ id: this.props.id, result: response.data, childID, parentID, name, descricao, childcount, level })
    })
    .catch(erro => console.log(erro))

    await Axios.post(`${Config.dominio}/php/sistema/generica_list.php`,
                      JSON.stringify({
                        tabela: 1,
                        local: sessionStorage.getItem('local')
                      })
    )
    .then( async (response) => {
      response.data.filter(item => item.valor != null).map(item => _lstPermissoes.push({ value: item.valor, label: item.descricao}))
      this.setState({ lstPermissoes: _lstPermissoes })
    })
    .catch(erro => console.log(erro))

    await Axios.post(`${Config.dominio}/php/sistema/permissaoFuncionalidade_list.php`,
                      JSON.stringify({idFuncionalidade: this.props.id})
    ) 
    .then( async (response) => {
      response.data.map(item => _lstPermissoes.filter(elem => elem.value === item.idPermissoes).map(elemento => _lstPermissoesEscolhidas.push(elemento)))
      this.setState({ lstPermissoesEscolhidas: _lstPermissoesEscolhidas })
    })
    .catch(erro => console.log(erro))

  }

  render() {
    return(
     <>
      <ToastContainer />
      <section className="content-header">
        <h1>
          Funcionalidades do Sistema
          <small>Acesso restrito</small>
        </h1>
        <ol className="breadcrumb">
          <li>
            <button 
              className="btn btn-danger btn-flat" 
              onClick={() => ReactDOM.render(<LstFuncionalidades />, document.getElementById('Content'))}
            >
              Voltar
            </button>&nbsp;&nbsp;
            <button className="btn btn-primary" onClick={this.salvar} >Salvar</button>
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="box">
          <div className="box-body">
            <div className="box">
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Descrição</h3>
                    <div className="box-body"> 
                      <div className="col-xs-6">
                        <div className="form-group">
                          <label htmlFor="nome">Nome</label>
                          <input 
                            type="text" 
                            value={this.state.name}
                            className="form-control" 
                            placeholder="" 
                            onChange={event => this.setState({name: event.target.value})}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="atividadeRealizada">Descrição</label>
                          <textarea 
                            className="form-control" 
                            rows="5"
                            defaultValue={this.state.descricao}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className="form-group">
                          <label htmlFor="nome">Permissões</label>
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={this.state.lstPermissoes}
                            style={{width: '100%'}}
                            value={this.state.lstPermissoesEscolhidas}
                            onChange={selectedOption => this.setState({lstPermissoesEscolhidas: selectedOption})}
                          />
                        </div>
                      </div>
                    </div>
                  <div className="box-footer">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    )
  }

}