import React, { Component } from 'react'
import ReactDOM from 'react-dom' 
import Axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader"
import Cliente from "./cliente"
import Config from '../../../config' 



export default class lstClienteMunicipio extends Component {

  state = {
            result: null,
            pag_atual: 1
          }

  relatorio() {
    if (this.state.result){
      if (this.state.result.length == 0 )
        return (
          <center><h2>Não há Clientes cadastradas.</h2></center>
        )
      
      return (
        <table className="table table-bordered table-striped">
          <thead>
            <tr> 
              <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>CNPJ/IBGE</th>
              <th style={{whiteSpace: "nowrap"}}>Nome</th>
              <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {this.state.result.sort((a,b) => a.nome >= b.nome? 1:-1).map((item, index) =>
              <tr key={index}>
                <td style={{textAlign: "center", whiteSpace: "nowrap"}}>{item.codigo}</td>
                <td style={{whiteSpace: "nowrap"}}>{item.nome}</td>
                <td style={{textAlign: "center"}}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" onClick={()=>ReactDOM.render(<Cliente id={item.id} action={`edit`} tipo={0} />, document.getElementById('Content'))}><i className="fa fa-edit"></i></button>
                    <button type="button" className="btn btn-danger" onClick={()=>ReactDOM.render(<Cliente id={item.id} action={`delete`} tipo={0} />, document.getElementById('Content'))}><i className="fa fa-trash"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
          <tr>
            <th style={{width: "1px", textAlign: "center", whiteSpace: "nowrap"}}>CNPJ/IBGE</th>
            <th style={{whiteSpace: "nowrap"}}>Nome</th>
            <th style={{width:"135px", textAlign: "center", whiteSpace: "nowrap"}}>Ações</th>
          </tr>
        </tfoot>
      </table>
      )
    }
  }
  
  paginacao(pagina) {
    // montar paginação depois de apresentar os dados
    switch(pagina) {
      case "-1":
        break
      case "+1": 
        break
      default:
        break
    }
  }

  async componentDidMount() {
    await Axios.post(`${Config.dominio}/php/sistema/cliente_list.php`,
                     JSON.stringify({
                        tipo: 0,
                        local: sessionStorage.getItem('local')
                     })
    )
    .then( async (response) => {
        this.setState({result: response.data})
    }) 
    .catch(erro => console.log(erro))
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Clientes
            <small>Listagem geral</small>
          </h1>
          <ol className="breadcrumb">
            <button className="btn btn-block btn-primary" onClick={()=>ReactDOM.render(<Cliente id={0} tipo={0} />, document.getElementById('Content'))}> Novo Cliente</button>
          </ol>
        </section>
        <section className="content">
          <div className="box">
            <p />
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span></button>
                  <ul className="dropdown-menu">
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="Nome"}>Nome</a></li>
                    <li><a href="#" onClick={() => document.getElementById("pesquisar").placeholder="CNPJ"}>CNPJ / IBGE</a></li>
                  </ul>
                </div>
                <input type="text" id="pesquisar" className="form-control" />
                <span className="input-group-btn">
                  <button type="button" className="btn btn-info btn-flat">Pesquisar</button>
                </span>
              </div>
            </div>
            <div className="box-tools">
              <ul className="pagination pagination-sm no-margin pull-right">
                <li><a href="#" onClick={() => this.paginacao("-1")} id="pag_anterior">&laquo;</a></li>
                <li><a>1 - 50</a></li>
                <li><a href="#" onClick={() => this.paginacao("+1")} id="pag_proxima">&raquo;</a></li>
              </ul>
            </div>
          </div>
          <div className="box-body">
            <p />
            <p />
            <ClipLoader
              css={{display: 'block', margin: '0 auto', borderColor: 'red'}}
              color={"#123abc"}
              loading={!this.state.result}
            />
            {this.state.result && this.relatorio()}
          </div>
        </section>
      </>
    )
  }
}