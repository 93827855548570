
import React, { Component, useEffect, useState } from 'react';
import HeaderSimples from '../../../common/HeaderSimples';
import Config from '../../../../config';
import { AxiosPost } from '../../../../lib/utils3';
import { usuario } from '../../../../lib/utils2';

const initialValue = {
    periodoInicial: '',
    periodoFinal: '',
    statusOs: '',
    problema: '',
    equipe: ''
}

function RelatoriosOrdemServico() {

    const [values, setValue] = useState(initialValue);
    const [listaProblemas, setListaProblemas] = useState([]);
    const [listaEquipes, setListaEquipes] = useState([]);

    function onChange(e) {
        const { name, value } = e.target;
        setValue({ ...values, [name]: value });
        //console.log(value);

    }

    const problemas = async () => {
        await AxiosPost(`${Config.dominio}/php/generica_list.php`, JSON.stringify({ tabela: 6 }))
            .then(async (response) => {
                setListaProblemas(response.data);

            })
    }

    const equipe = async () => {
        await AxiosPost(`${Config.dominio}/php/equipe/tecnico_list.php`, JSON.stringify({ a: 1 }))
            .then(async (response) => {
                setListaEquipes(response.data);

            })
    }

    useEffect(() => {
        problemas();
        equipe();
    }, []);


    function verificaForm() {
        if (document.getElementById("periodoInicial").value == "" || document.getElementById("periodoFinal").value == "") {
            alert("Insira um período para gerar relatório.")
        } else {
            document.forms["formPrint"].submit()
        }
    }

    return (
        <>
            <HeaderSimples titulo='Relatório' subtitulo='Ordem Serviço' />
            <form
                target="_blank"
                id="formPrint"
                action={`${Config.dominio}/php/relatorios/ordemServico.php`}
                method="post"
            >
                {console.log(usuario())}
                <input type="hidden" name="userSys" value={usuario().nome} />
                <input type="hidden" name="municipio" value={usuario().municipio.nome} />
                <input type="hidden" name="baseMunicipio" value={usuario().municipio.dbase} />
                <div className="col-md-12">
                    <div className="box box-default">
                        <div className="box-header">
                            <h3 className="box-title">Situação da ordem de serviço</h3>
                        </div>
                        <div className="box-body">
                            <div>
                                <label className="radio-inline"><input type="radio" value="1" name="statusOs" onChange={onChange} /> Abertas</label>
                                <label className="radio-inline"><input type="radio" value="2" name="statusOs" onChange={onChange} />Aprovada execução</label>
                                <label className="radio-inline"><input type="radio" value="3" name="statusOs" onChange={onChange} />Distribuídas</label>
                                <label className="radio-inline"><input type="radio" value="4" name="statusOs" onChange={onChange} />Executadas</label>
                                <label className="radio-inline"><input type="radio" value="6" name="statusOs" onChange={onChange} />Cancelada</label>
                                <label className="radio-inline"><input type="radio" value="7" name="statusOs" onChange={onChange} />Notificação falsa</label>
                                <label className="radio-inline"><input type="radio" value="" name="statusOs" defaultChecked onChange={onChange} />Todas</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">

                    <div className="box box-default">
                        <div className="box-header">
                            <h3 className="box-title">Período</h3>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Início:</label>
                                            <div className="input-group date">
                                                <div className="input-group-addon">
                                                    <i className="fa fa-calendar"></i>
                                                </div>
                                                <input type="date" name="periodoInicial" onChange={onChange} className="form-control pull-right" id="periodoInicial" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Final:</label>
                                            <div className="input-group date">
                                                <div className="input-group-addon">
                                                    <i className="fa fa-calendar"></i>
                                                </div>
                                                <input type="date" name="periodoFinal" onChange={onChange} className="form-control pull-right" id="periodoFinal" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer">
                                <button type="button" className="btn btn-block btn-success" onClick={() => verificaForm()}>Gerar relatório</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="box box-default">
                        <div className="box-header">
                            <div className="box-body">

                                <div className="row mb-5">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="equipe">Equipe:</label>
                                            <div className="">
                                                <select id="equipe" name="equipe" onChange={onChange} className="form-control">
                                                    <option value="">Selecione...</option>
                                                    {listaEquipes.map(opt =>
                                                        <option
                                                            key={opt.id}
                                                            value={opt.id}
                                                        >
                                                            {opt.nome}
                                                        </option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="problema">Problema:</label>
                                            <div className="">
                                                <select id="problema" name="problema" onChange={onChange} className="form-control">
                                                    <option value="">Selecione...</option>
                                                    {listaProblemas.map(opt =>
                                                        <option
                                                            key={opt.id}
                                                            value={opt.descricao}
                                                        >
                                                            {opt.descricao}
                                                        </option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Contribuinte:</label>
                                            <input type="text" name="contribuinte" onChange={onChange} className="form-control" id="contribuinte" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="box box-default">
                        <div className="box-header">
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Usuário:</label>
                                            <input type="text" name="usuario" onChange={onChange} className="form-control" id="usuario" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Bairro:</label>
                                            <input type="text" name="bairro" onChange={onChange} className="form-control" id="bairro" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>Rua:</label>
                                            <input type="text" name="rua" onChange={onChange} className="form-control" id="rua" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );

} export default RelatoriosOrdemServico;

