import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { AxiosPost } from '../../../lib/utils3'
import ClipLoader from "react-spinners/ClipLoader"
import DetalheOS from './acompanhamento/detalheOS'
import Select from 'react-select'
import { format } from 'date-fns'
import makeAnimated from 'react-select/animated'
import Config from '../../../config'

const animatedComponents = makeAnimated()

const scrollList = {
  overflowX: 'scroll',
  width: '100%'
}


export default class lstOrdensDeServico extends Component {

 constructor(props){ 
   super(props)
  this.state = {
    result: [],
    lstStatusPesquisa: null,
    lstEquipe: [],
    tecnicos: [],
    lstBairros: [],
    bairro: [],
    lstDistritos: [],
    distrito: [],
    lstStatus: [],
    status: [],
    showFiltro: true,
    paginacao: 16,
    totalRegistros: 0,
    pag_atual: 1,
    loadingSpin: true,
    filtroValor: "",
    filtroPlaceholder: "OS",
    load: false,
    lstTipoOs: [{ value: '0', label: 'Ornamental' },
      { value: '1', label: 'Convencional' }],
      tipoOs: [],
      dataFiltro: [],
      filtroOrdensServico: [],
      selectedOption: null,
    };
    this.listaOrdensServico = this.listaOrdensServico.bind(this);
  }

  inputSelectBusca (e) {
    if(e?.value){
        let arrayFiltrado = 
        this.state.filtroPlaceholder === "Tipo de os"
        ? this.state.dataFiltro.filter((os) => os.tipo_os?.includes(e.value))
        : this.state.filtroPlaceholder === "Bairro"
        ? this.state.dataFiltro.filter((os) => os.bairro?.includes(e.value))
        : []
        this.setState({ filtroOrdensServico: arrayFiltrado, filtroValor: e.value });
    } else {
      this.setState({ filtroValor: "" })
    }
  }

  buscaInput = e => {
    e.preventDefault();
    let valor = e.target.value 
 
     let arrayFiltrado = 
     valor.length > 0 && this.state.filtroPlaceholder === "OS"
     ? this.state.dataFiltro.filter((os) => os.protocolo.includes(valor))
     : valor.length > 0 && this.state.filtroPlaceholder === "Data de Abertura"
     ? this.state.dataFiltro.filter((os) => os.datahora_abertura.includes(valor))
     : valor.length > 0 && this.state.filtroPlaceholder === "Logradouro"
     ? this.state.dataFiltro.filter((os) => os.rua.includes(valor))
     : [];
 
     this.setState({ filtroOrdensServico: arrayFiltrado, filtroValor: valor });
   }
 


  async listaStatus() {
    await AxiosPost(`${Config.dominio}/php/generica_list.php`, JSON.stringify({ tabela: 5 }))
      .then(async response => {
        let lista = []
        response.data.map(item => !['0', '5'].includes(item.valor) && lista.push({ value: item.valor, label: item.descricao }))
        this.setState({ lstStatus: lista })
      })
      .catch(erro => console.log(erro))
  }

  async listaTecnico() {
    await AxiosPost(`${Config.dominio}/php/equipe/tecnico_list.php`, JSON.stringify({
      a: 1
    }))
      .then(async response => {
        let lista = []
        response.data.map(item => lista.push({ value: item.id, label: item.nome }))
        this.setState({ lstEquipe: lista })
      })
      .catch(erro => console.log(erro))
  }

  async listaOrdensServico() {
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoLstGeral_list.php`, JSON.stringify({
      paginacao: this.state.paginacao,
      pagina: this.state.pag_atual
    }))
      .then(async function (response) {
        this.setState({
                        result: response.data,
                        loadingSpin: false
                     })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async filtro() {
    await AxiosPost(`${Config.dominio}/php/os/ordemServicoLstGeral_list.php`, JSON.stringify({
      status: null
    }))
      .then(async function (response) {
        let bairros = []
        let _bairros = []
        response.data.map(item => bairros.push(item.bairro))
        bairros = [...new Set(bairros)]
        bairros.map(item => item != "null" && _bairros.push({ value: item, label: item }))

        this.setState({
          dataFiltro: response.data,
          lstBairros: _bairros,
        })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  relatorio(arrayOs) {
      return (
        <>
        <div style={scrollList}>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Emissão</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>OS</th>
              <th style={{ width: "1px", whiteSpace: "nowrap" }}>Equipe</th>
              <th style={{ whiteSpace: "nowrap" }}>Descrição do problema</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Tipo OS</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Contribuinte</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Situação</th>
              <th style={{ width: "1px", textAlign: "center", whiteSpace: "nowrap" }}>Baixada em</th>
              <th style={{ width: "175px", textAlign: "center", whiteSpace: "nowrap" }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {arrayOs.map((item, index) =>
              <tr key={index}>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{format(new Date(item.datahora_abertura), "dd/MM/yyyy HH:mm")}</td>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{item.protocolo}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.equipe_tecnicos}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.desc_problema}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.tipo_os === '0' ? 'Ornamental' : item.tipo_os === '1' ? 'Convencional' : ''}</td>
                <td style={{ whiteSpace: "nowrap" }}>{item.nome_contribuinte}</td>
                <td style={{ whiteSpace: "nowrap" }}>{this.state.lstStatus.length > 0 && this.state.lstStatus.find(st => st.value == item.id_status_atual).label}</td>
                <td style={{ whiteSpace: "nowrap" }}>{[4, 6, 7].find(i => i == item.id_status_atual) ? format(new Date(item.datahora_atual), "dd/MM/yyyy HH:mm") : ''}</td>
                <td style={{ textAlign: "center" }}>
                  <div className="btn-group">
                    <button type="button" className="btn btn-success" alt="teste" onClick={() => ReactDOM.render(<DetalheOS protocolo={`${item.protocolo}`} edit={1} />, document.getElementById('Content'))}><i className="fa fa-sitemap"></i></button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
        </>
      )
  }

  async pesquisar() {
    this.state.load = true
    let filtroValor = this.state.filtroValor
    let filtroPlaceholder = this.state.filtroPlaceholder
    let _lstBairros = []
    this.state.bairro.length > 0 && this.state.bairro.map(item => _lstBairros.push(item.value))
    let _lstDistritos = []
    this.state.distrito.length > 0 && this.state.distrito.map(item => _lstDistritos.push(item.value))
    await AxiosPost(`${Config.dominio}/php/os/ordemServico_list.php`,
      JSON.stringify({
        filtro_barramento: filtroPlaceholder == 'Barramento' ? filtroValor : '',
        filtro_distrito: filtroPlaceholder == 'Distrito' ? _lstDistritos.toString() : '',
        filtro_bairro: filtroPlaceholder == 'Bairro' ? _lstBairros.toString() : '',
        filtro_rua: filtroPlaceholder == 'Logradouro' ? filtroValor : '',
        filtro_os: filtroPlaceholder == 'OS' ? filtroValor : '',
        filtro_dataAbertura: filtroPlaceholder == 'Data de Abertura' ? this.state.dataAbertura : ''
      })
    )
      .then(async function (response) {
        let bairros = []
        let _bairros = []
        let distritos = []
        let _distritos = []

        if (this.state.filtroPlaceholder == 'StatusOS' && this.state.status.length > 0) {
          response.data = response.data.filter(item => this.state.status.map(st => st.value).includes(item.id_status_atual))
        }

        response.data.map(item => bairros.push(item.bairro))
        bairros = [...new Set(bairros)]
        bairros.map(item => item != "null" && _bairros.push({ value: item, label: item }))

        response.data.map(item => distritos.push(item.distrito + '|' + item.id_distrito))
        distritos = [...new Set(distritos)]
        distritos.map(item => item != "null|null" && _distritos.push({ value: item.split("|")[1], label: item.split("|")[0] }))

        this.setState({
          result: response.data,
          totalRegistros: response.data.length,
          lstBairros: _bairros,
          lstDistritos: _distritos,
          load: false
        })
      }.bind(this))
      .catch(erro => console.log(erro))
  }

  async componentDidMount() {
    this.listaStatus()
    this.listaTecnico()
    this.listaOrdensServico()
    this.filtro()
  }


  paginacao(pagina) {
    let pag_atual = this.state.pag_atual
    this.setState({ pag_atual: pag_atual + pagina})
    this.listaOrdensServico()
  }

  render() {
    return (
      <>
        <section className="content-header">
          <h1>
            Listagem Geral das Ordens de Serviço
          </h1>
          <ol className="breadcrumb">
          </ol>
        </section>
        <section className="content">
          <div className="box">
            <p />
            <div className="col-md-6">
              <div className="input-group">
                <div className="input-group-btn">
                  <button type="button" className="btn btn-warning dropdown-toggle" data-toggle="dropdown">Filtro&nbsp;&nbsp;
                    <span className="fa fa-caret-down"></span>
                  </button>
                  <ul className="dropdown-menu">
                      {/* <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Barramento' })}>Barramento</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Distrito' })}>Distrito</a></li> */}
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Bairro' })}>Bairro</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Logradouro' })}>Logradouro</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'OS' })}>OS</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Tipo de os' })}>Tipo de os</a></li>
                      <li><a href="#" onClick={() => this.setState({ filtroPlaceholder: 'Data de Abertura' })}>Data</a></li>
                    </ul>
                  </div>
                  {this.state.filtroPlaceholder != 'Distrito' &&
                    this.state.filtroPlaceholder != 'Bairro' &&
                    this.state.filtroPlaceholder != 'Tipo de os' &&
                    this.state.filtroPlaceholder != 'Data de Abertura' &&
                    <input type="text" onChange={this.buscaInput} className="form-control" placeholder={this.state.filtroPlaceholder} />
                  }
                  {this.state.filtroPlaceholder == 'Distrito' &&
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={this.state.lstDistritos}
                      style={{ width: '100%' }}
                      value={this.state.distrito}
                      onChange={selectedOption => this.setState({ distrito: selectedOption })}
                    />
                  }
                  {this.state.filtroPlaceholder == 'Bairro' &&
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={this.state.lstBairros}
                      style={{ width: '100%' }}
                      isClearable
                      defaultInputValue=""
                      onChange={(e) => this.inputSelectBusca(e)}
                    />
                  }
                  {this.state.filtroPlaceholder == 'Data de Abertura' &&
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.dataAbertura}
                      onChange={this.buscaInput}
                    />
                  }
                  {this.state.filtroPlaceholder == 'Tipo de os' &&
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={this.state.lstTipoOs}
                      style={{ width: '100%' }}
                      isClearable
                      defaultInputValue=""
                      onChange={(e) => this.inputSelectBusca(e)}
                    />
                  }
                
              </div>
            </div>
            <div className="box-tools">
              <ul className="pagination pagination-sm no-margin pull-right">
                {this.state.pag_atual > 1 &&
                  <li><a href="#" onClick={() => this.paginacao(-1)}>&laquo;</a></li>
                }
                <li>
                  <a>
                    {(this.state.pag_atual - 1) * (this.state.paginacao - 1) + 1} - {this.state.pag_atual * (this.state.paginacao - 1)}
                  </a>
                </li>
                {this.state.result && this.state.result.length == this.state.paginacao &&
                  <li><a href="#" onClick={() => this.paginacao(+1)}>&raquo;</a></li>
                }
              </ul>
            </div>

          </div>
          <p />
          {this.state.filtroValor !== "" && this.state.filtroValor.length > 0 ?(
            
             <div className="box-body">
               <p />
               {this.relatorio(this.state.filtroOrdensServico)}
       
           </div> 
          ): (
            <div className="box-body">
              <p />
              {this.relatorio(this.state.result)}
          </div>
          )
            }
        </section>
        <ClipLoader
          css={{ display: 'block', margin: '0 auto', borderColor: 'red' }}
          color={"#123abc"}
          loading={this.state.loadingSpin}
        />
      </>
    )
  }
} //senha bitbucket: L9d4TEfkKNRGpWrs6z2q